<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="创建时间：" label-width="100">
        <el-date-picker
          v-model="searchForm.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy年MM月dd日"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="用户id" label-width="100">
        <el-input v-model="searchForm.userId" placeholder="用户id" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="用户昵称" label-width="100">
        <el-input
          v-model="searchForm.nickName"
          placeholder="用户昵称"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="手机号" label-width="100">
        <el-input v-model="searchForm.phone" placeholder="手机号" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="用户状态" label-width="100">
        <el-select
          v-model="searchForm.status"
          clearable
          placeholder="选择用户状态"
        >
          <el-option label="无效" value="0"></el-option>
          <el-option label="有效" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="身份" label-width="100">
        <el-select
          v-model="searchForm.identity"
          clearable
          placeholder="选择身份"
        >
          <el-option label="货主" :value="2"></el-option>
          <el-option label="司机" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否认证" label-width="100">
        <el-select
          v-model="searchForm.isAuthentication"
          clearable
          placeholder="选择是否认证"
        >
          <el-option label="未认证" :value="0"></el-option>
          <el-option label="认证中" :value="2"></el-option>
          <el-option label="已认证" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getsearchF" type="success">搜索</el-button>
        <el-button type="primary" @click="exportSurfList()">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="用户id" align="center" prop="userId" width="80">
      </el-table-column>
      <el-table-column
        label="用户昵称"
        align="center"
        prop="nickName"
        min-width="160"
      >
      </el-table-column>
      <el-table-column label="用户头像" align="center" width="80">
        <template slot-scope="scope">
          <el-avatar size="small" :src="scope.row.avatarUrl"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center" prop="phone" width="120">
      </el-table-column>
      <el-table-column
        label="真实姓名"
        align="center"
        prop="fullName"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="status" align="center" label="用户状态" width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status === 0" type="info"
            >封号</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.status === 1" type="success"
            >有效</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="identity" align="center" label="身份" width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.identity === 2" type="info"
            >货主</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.identity === 3"
            type="success"
            >司机</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="isAuthentication"
        align="center"
        label="是否认证"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-if="scope.row.isAuthentication === 0"
            type="info"
            >未认证</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.isAuthentication === 1"
            type="success"
            >已认证</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.isAuthentication === 2"
            type="success"
            >认证中</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="identity"
        align="center"
        label="有效推荐人数"
        width="120"
      >
        <template slot-scope="{ row }">
          <div style="cursor: pointer; color: #409eff" @click="share(row)">
            {{ row.levelUserCout || "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="levelUserId"
        align="center"
        label="推荐人ID"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        align="center"
        width="160"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        prop="icon"
        align="center"
        width="210"
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button type="primary" plain @click="detail(scope.row)"
            >详情</el-button
          >
          <el-dropdown placement="bottom" trigger="hover">
            <el-button type="success" plain>
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="Edit(scope.row)"
                command="a"
                v-if="scope.row.identity === 3"
                >补充认证材料</el-dropdown-item
              >
              <el-dropdown-item
                v-if="hasAuth('sys:userbase:downloadqrcode')"
                @click.native="downloadCodeImg(scope.row)"
                >下载推荐码</el-dropdown-item
              >
              <el-dropdown-item @click.native="share(scope.row)"
                >分享推荐列表</el-dropdown-item
              >
              <el-dropdown-item @click.native="exportShare(scope.row)"
                >分享推荐导出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>
    <usershare ref="sharev" v-if="sharevisible" />
    <userdetail ref="shared" v-if="detailvisible" />
    <UserEdit ref="edit" v-if="editvisible" />
  </div>
</template>

<script>
import usershare from "./usershare";
import userdetail from "./UserDetail.vue";
import UserEdit from "./UserEdit.vue";
import ty from "@/utils/ty";
export default {
  name: "UserBase",
  components: {
    usershare,
    userdetail,
    UserEdit,
  },
  data() {
    return {
      editvisible: false,
      searchForm: {},
      delBtlStatu: true,
      sharevisible: false,
      updateShareCodevisible: false,
      updateRedPacketvisible: false,
      massMessagingvisible: false,
      commissionDetailsvisib: false,
      tableData: [],
      detailvisible: false,
      prefixBase64: "data:image/png;base64,", // base64前缀
      multipleSelection: [],
      total: 0,
      size: 10,
      current: 1,
    };
  },
  created() {
    this.getUserBase();
  },
  mounted() {
    // 判断浏览器是否是火狐
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      this.isFirefox = true;
    }
  },
  methods: {
    handleCommand(e, command) {
      console.log(e);
      console.log(command);
    },
    exportSurfList() {
      window.open(ty.apiurl + "/admin/userBase/exportUserList");
    },

    exportShare(row) {
      window.open(ty.apiurl + '/admin/userBase/exportShareUserList?userId='+row.userId)
    },
    share(row) {
      this.sharevisible = true;
      this.$nextTick(() => {
        this.$refs.sharev.init(row);
      });
    },
    detail(row) {
      this.detailvisible = true;
      this.$nextTick(() => {
        this.$refs.shared.init(row.userId);
      });
    },
    Edit(row) {
      this.editvisible = true;
      this.$nextTick(() => {
        this.$refs.edit.init(row.userId);
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.delBtlStatu = val.length == 0;
    },

    handleSizeChange(val) {
      this.current = 1;
      this.size = val;
      this.getUserBase();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getUserBase();
    },
    getsearchF() {
      this.current = 1;
      this.size = 10;
      this.getUserBase();
    },
    downloadCodeImg(picUrl, nickName) {
      console.log("下载图片");
      this.$axios
        .post("/admin/userBase/getImgPath", { path: picUrl })
        .then((res) => {
          // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
          const imgUrl = this.prefixBase64 + res.data.pathBase64;
          // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
          if (window.navigator.msSaveOrOpenBlob) {
            let bstr = atob(imgUrl.split(",")[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            let blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(blob, nickName + "." + "png");
          } else {
            // 这里就按照chrome等新版浏览器来处理
            let a = document.createElement("a");
            a.href = imgUrl;
            a.setAttribute("download", nickName);
            a.click();
          }
        });
    },

    //获取标签类型列表
    getUserBase() {
      var startTime = "";
      var endTime = "";
      if (this.searchForm.createTime != undefined) {
        startTime = this.searchForm.createTime[0];
        endTime = this.searchForm.createTime[1];
      }
      this.searchForm.startTime = startTime;
      this.searchForm.endTime = endTime;
      this.searchForm.pageNo = this.current;
      this.searchForm.pageSize = this.size;
      this.$axios
        .post("/admin/userBase/getUserBase", this.searchForm)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
    },
    //封号
    usertitle(id) {
      this.$axios
        .post("/admin/userBase/usertitle", { userId: id })
        .then((res) => {
          this.$message({
            showClose: true,
            message: "恭喜你，操作成功",
            type: "success",
            onClose: () => {
              this.getUserBase();
            },
          });
        });
    },
    //解封
    userUnseal(id) {
      this.$axios
        .post("/admin/userBase/userUnseal", { userId: id })
        .then((res) => {
          this.$message({
            showClose: true,
            message: "恭喜你，操作成功",
            type: "success",
            onClose: () => {
              this.getUserBase();
            },
          });
        });
    },
    downloadCodeImg(user) {
      const loading = this.$loading({
        lock: true,
        text: "正在下载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios
        .post("/admin/userBase/downloadQrCode", { userId: user.userId })
        .then((res) => {
          // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
          const imgUrl = this.prefixBase64 + res.data.qrCodeUrl;
          // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
          if (window.navigator.msSaveOrOpenBlob) {
            let bstr = atob(imgUrl.split(",")[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            let blob = new Blob([u8arr]);
            window.navigator.msSaveOrOpenBlob(
              blob,
              user.nickName + "." + "png"
            );
          } else {
            // 这里就按照chrome等新版浏览器来处理
            let a = document.createElement("a");
            a.href = imgUrl;
            a.setAttribute("download", user.nickName);
            a.click();
          }
        });
      loading.close();
    },
  },
};
</script>

<style scoped>
.el-button {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  margin-right: 10px;
}
</style>
